






















































































































@import "../../../../assets/scss/custom/_filesList.scss";
.upload-demo input {
	display: none;
}
