













































































































































































































































































































































































































































































































.panel-container {
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
	margin-top: 20px;
	margin-left: 0px;
	margin-right: 0px;
}

.rule_set-container {
	.el-collapse-item__content {
		margin-left: 30px;
		margin-right: 30px;
	}
}
.rules-container {
	padding: 10px;
	.rules-collapse {
		margin: 30px;
		.rule-item {
			border: 1px solid #e2e2e2;
		}
		.editable-item {
			margin: 20px;
		}
	}
	.rules-header {
		margin-left: 20px;
	}
}
