<template>
	<div class="sidebar" :data-color="backgroundColor">
		<div class="sidebar-wrapper" ref="sidebarScrollArea">
			<slot></slot>
			<ul class="nav">
				<slot name="links">
					<sidebar-item
						v-for="(link, index) in sidebarLinks"
						:key="link.name + index"
						:link="link"
					>
						<sidebar-item
							v-for="(subLink, index) in link.children"
							:key="subLink.name + index"
							:link="subLink"
						>
						</sidebar-item>
					</sidebar-item>
				</slot>
			</ul>
		</div>
	</div>
</template>
<script lang="ts">
import { Component, Prop, Provide, Vue } from "vue-property-decorator";

@Component
export default class Sidebar extends Vue {
	@Prop({
		type: String,
		default: "black",
		validator: (value) => {
			const acceptedValues = [
				"",
				"white",
				"blue",
				"azure",
				"green",
				"orange",
				"red",
				"purple",
				"black"
			];
			return acceptedValues.indexOf(value) !== -1;
		}
	})
	backgroundColor!: string;

	@Prop({ type: Array, default: () => [] }) sidebarLinks!: Array<object>;
	@Prop({ type: Boolean, default: true }) autoClose!: boolean;

	@Provide("autoClose") providedAutoClose = this.autoClose;

	minimizeSidebar() {
		if (this.$sidebar) {
			this.$sidebar.toggleMinimize();
		}
	}
	beforeDestroy() {
		if (this.$sidebar.showSidebar) {
			this.$sidebar.showSidebar = false;
		}
	}
}
</script>
<style>
@media (min-width: 992px) {
	.navbar-search-form-mobile,
	.nav-mobile-menu {
		display: none;
	}
}
</style>
